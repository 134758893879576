/**
 * Created by Landori on 2017. 03. 22..
 */

app.controller('megrendeles', [
  '$scope', '$http', 'localStorageService', function($scope, $http, localStorageService) {

    $scope.activeDay = 1;
    $scope.weekObj = [];
    $scope.napi = [];
    $scope.cart = {};
    $scope.globalCart = [];
    $scope.vegosszegList = [];
    $scope.successorder = false;

    for (i = 0; i < 6; i++) {
      $scope.globalCart[i] = [];
      $scope.vegosszegList[i] = [];
    }
    var d = new Date();
    var week = 0;
    $scope.einladung = true;

    //ha péntek,szombat, vasárnap van, akkor a következő heti megrendelést töltse be
    if ( ( d.getDay() === 7 || d.getDay() === 0 ) && d.getWeekNumber() < 52 ) {
      week = parseInt(d.getWeekNumber()) + 1;
    } else if ( d.getDay() > 6 && d.getWeekNumber() === 52 ) {
      week = 1;
    } else {
      week = d.getWeekNumber();
    }

    var r = $http({
      method: 'GET',
      url: '/api/hetimenu',
      params: { date: d.getFullYear() + '/' + week },
    });

    r.then(function(data) {
      $scope.weekObj = data.data;
      var iksz = 0;

      angular.forEach($scope.weekObj.menu, function(napimenu, nap) {

        angular.forEach(napimenu, function(value, key) {
          iksz = key;
          $scope.weekObj.menu[nap][key].cat = 'Napi menü';
        });
        angular.forEach($scope.weekObj.kiemelt, function(value, key) {
          iksz++;
          $scope.weekObj.kiemelt[key].cat = 'Kiemelt ajánlat';
          $scope.weekObj.kiemelt[key].ar = angular.fromJson($scope.weekObj.kiemelt[key].ar);

          $scope.weekObj.menu[nap][iksz] = $scope.weekObj.kiemelt[key];
        });
        angular.forEach($scope.weekObj.egesz_heten, function(value, key) {
          iksz++;
          $scope.weekObj.egesz_heten[key].cat = 'Egész héten rendelhető';
          $scope.weekObj.menu[nap][iksz] = $scope.weekObj.egesz_heten[key];
        });

      });

      iksz = 0;
      angular.forEach($scope.weekObj.koret, function(value, key) {
        iksz = key;
        $scope.weekObj.koret[key].cat = 'Alap köret';

      });

      angular.forEach($scope.weekObj.felaras_koret, function(value, key) {
        iksz++;
        $scope.weekObj.koret[iksz] = $scope.weekObj.felaras_koret[key];
        $scope.weekObj.koret[iksz].cat = 'Feláras köret';
      });
      $scope.einladung = false;
    }, function(error) {
      alert('Hiba! Próbálj meg telefonon rendelni!');
      throw new Error('Nem sikerült letölteni a napi menüt');
    });

    $scope.setActiveDay = function(day, action) {
      $scope.cart = {};
      $scope.activeDay = day;

    };

    $scope.foetelFilter = function(item) {
      if ( item.ar === 0 && item.ar.hetkoznap === undefined ) {
        return false;
      } else {
        return true;
      }
    };

    $scope.getVariation = function(item) {
      if ( item != undefined || item != null ) {
        return item.split(';');
      }
    };

    $scope.getAr = function(item, action) {
      if ( item === undefined )
        return;

      var re = null;
      if ( item.hetkoznap === undefined && item.hetkoznap == null ) {
        re = item;
      } else {
        var d = new Date();
        if ( d.getDay() > 5 ) {
          re = item.hetvege;
        } else {
          re = item.hetkoznap;
        }
      }
      if ( action ) {
        return re;
      } else {
        return re + ' Ft';
      }
    };

    $scope.addFood = function() {
      $scope.addfood_status = 1;
    };

    $scope.vegosszeg = function(tomb) {
      var leves = 0, szum = 0, foetel = 0, ignore = 0;

      angular.forEach(tomb, function(value, key) {

        if ( key === 'leves' && value !== null ) {
          leves = 1;
        }
        if ( key === 'foetel' && value !== null ) {

          if ( value.ignore_leves === true ) {
            ignore = 1;
          }
          foetel = 1;
        }

        if ( value && value.ar !== undefined ) {
          szum += $scope.getAr(value.ar, true);
        }
      });

      if ( foetel && !leves && !ignore ) {
        szum = szum - 100;
      }
      if ( leves && !foetel || leves && ignore && foetel) {
        szum = szum + 450;
      }

      return szum;
    };

    $scope.totalSum = function(glob_cart, action) {
      var tsz = 0;
      var temp = 0;
      angular.forEach(glob_cart, function(napirendeles, nap) {
        temp = 0;
        angular.forEach(napirendeles, function(value, key) {
          var vo = $scope.vegosszeg(value[0]);
          tsz += vo;
          temp += vo;
        });
        if ( action ) {
          $scope.vegosszegList[nap] = temp;
        }
      });

      return tsz;
    };

    $scope.toGlobalCart = function(cart) {
      $scope.globalCart[$scope.activeDay].push([cart]);
      $scope.cart = {};
    };

    $scope.removeGlobalCart = function(index) {
      $scope.globalCart[$scope.activeDay].splice(index, 1);
    };

    $scope.checkout = function() {
      $scope.saving = false;
      $scope.successorder = false;
      var pop = jQuery('#megrendelesModal');
      pop.modal('show');
      $scope.user = {
        name: localStorageService.get('name'),
        tel: localStorageService.get('tel'),
        email: localStorageService.get('email'),
        address: localStorageService.get('address'),
        date: d.getFullYear() + '/' + week,
      };
    };

    $scope.megrendel = function() {

      $scope.saving = true;
      $scope.totalSum($scope.globalCart, 1);

      var param = angular.toJson({
        value: angular.toJson($scope.globalCart),
        userdata: angular.toJson($scope.user), vegosszegek: angular.toJson($scope.vegosszegList),
      });

      $http.post('/api/megrendeles', param).then(function() {
        for (i = 0; i < 6; i++) {
          $scope.globalCart[i] = [];
          $scope.vegosszegList[i] = [];
        }
        $scope.successorder = true;
        $scope.saving = false;
      }, function(error) {
        alert('Hiba! Próbálj meg telefonon rendelni!');
        throw new Error('Sikertelen rendelés' + error);
      });

      localStorageService.set('name', $scope.user.name);
      localStorageService.set('tel', $scope.user.tel);
      localStorageService.set('email', $scope.user.email);
      localStorageService.set('address', $scope.user.address);

    };
    $scope.resetKoret = function() {
      $scope.cart.koret = null;
    };
    $scope.$watch('cart', function(newvalue, oldvalue, scope) {
      if ( ( $scope.cart.foetel && $scope.cart.foetel.koret === true && !$scope.cart.koret ) ||
          ( $scope.cart.foetel && $scope.cart.foetel.variaciok && !$scope.cart.foetel.variacio ) ||
          ( $scope.cart.desszert && $scope.cart.desszert.variaciok &&
              !$scope.cart.desszert.variacio ) ) {

        $scope.backdrop = true;
      } else {
        $scope.backdrop = false;
      }

    }, true);
  }]);