/**
 * Created by Landori on 2017. 03. 17..
 */
var app = angular.module('piccolo',['ngRaven','LocalStorageModule']);

app.factory('$exceptionHandler', function () {
        return function errorCatcherHandler(exception, cause) {
            console.error(exception.stack);
             Raven.captureException(exception);
            // do not rethrow the exception - breaks the digest cycle
        };
});
app.config(['$httpProvider', function($httpProvider) {
    $httpProvider.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
    $httpProvider.interceptors.push(['$q', function($q) {
        return {
            request: function(config) {
                config.url = config.url + "?_=" +new Date().getTime();

                if (config.data && typeof config.data === 'object') {
                    config.data = $.param(config.data);
                }
                return config || $q.when(config);
            }
        };
    }]);
}]);
Date.prototype.getWeekNumber = function(){
    var d = new Date(+this);
    d.setHours(0,0,0,0);
    d.setDate(d.getDate()+4-(d.getDay()||7));
    return Math.ceil((((d-new Date(d.getFullYear(),0,1))/8.64e7)+1)/7);
};
